<template>
  <div>
    {{order}}
  </div>
</template>

<script>


import ClickToEdit from "@/components/ClickToEdit.vue";
import {ElementScalePropTableColumn} from "@/mixins/elementScalePropTableColumn";
import {mapGetters} from "vuex";

export default {
  name: 'element-order-table-column',
  components: {ClickToEdit},
  mixins: [ElementScalePropTableColumn],

  props: {},

  computed: {

    ...mapGetters('card/sections', {
      sections: 'sections',
      sectionsElements: 'sectionsElements'
    }),
    order() {
      const thisSectionType = this.scope._self.section.type;
      if( thisSectionType === 'required' ){
        return this.scope.$index+1;
      }

      const requiredSection = this.sections.find(s => s.type === 'required');
      const requiredSectionCount = requiredSection ? this.sectionsElements[requiredSection.id]?.length : 0;

      if( thisSectionType === 'functional' ){
        return requiredSectionCount + (+this.scope.$index+1);
      }

      const functionalSection = this.sections.find(s => s.type === 'functional');
      const functionalSectionCount = functionalSection ? this.sectionsElements[functionalSection.id]?.length : 0;

      if( thisSectionType === 'career' ){
        return requiredSectionCount + functionalSectionCount + (+this.scope.$index+1);
      }

      const careerSection = this.sections.find(s => s.type === 'career');
      const careerSectionCount = careerSection ? this.sectionsElements[careerSection.id]?.length : 0;

      if( thisSectionType === 'growth' ){
        return requiredSectionCount + functionalSectionCount + careerSectionCount + (+this.scope.$index+1);
      }

      return 999;
    },
  },
  data() {
    return {

    }
  },

  methods: {

  }
}

</script>

<style>

</style>
